<template>
  <div class="about">
    <!-- <el-button @click="getList" type="primary">主要按钮</el-button> -->
    <slideshow :slideshowList="slideshowList"></slideshow>
    <img class="debris" src="../../../static/debris.png" />
    <img
      class="Aboutbg"
      style="top: 17%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 60%; right: 0"
      src="../../../static/crane.png"
    />
    <img
      class="Aboutbg"
      style="top: 33%; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 56%; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 0; left: 0"
      src="../../../static/wasteL.png"
    />
    <img
      class="Aboutbg"
      style="top: 34%; right: 0"
      src="../../../static/wasteR.png"
    />
    <img
      class="Aboutbg"
      style="bottom: 13%; right: 0"
      src="../../../static/wasteR.png"
    />
    <el-container>
      <el-main>
        <div>
          <div id="general" class="general" style="padding: 120px 80px 0 80px">
            <div style="width: 100%">
              <div class="top-title">
                <img
                  class="home-icon"
                  src="../../../static/scenery/home.png"
                  alt=""
                  style="width: 20; height: 20"
                />
                <span
                  style="margin-right: 6px; font-weight: bold"
                  @click="Jump('/', 0)"
                  >首页</span
                >
                >
                <span style="margin-left: 6px" @click="Jump('/about')"
                  >走进条子泥</span
                >
                >
                <span style="margin-left: 6px; color: #509ab1">条子泥印象</span>
                <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item
                    :to="{ path: '/' }"
                    @click.native="$store.commit('active', 0)"
                  >
                    <img
                      class="home-icon"
                      src="../../../static/scenery/home.png"
                      alt=""
                      style="width: 20; height: 20"
                    />
                    首页</el-breadcrumb-item
                  >
                  <el-breadcrumb-item>走进条子泥</el-breadcrumb-item>
                  <el-breadcrumb-item>条子泥印象</el-breadcrumb-item>
                </el-breadcrumb> -->
              </div>
              <!-- <hr /> -->
              <div class="hr-more">
                <div>
                  <!-- <img
                    style="transform: rotateY(180deg);"
                    width="43px"
                    src="../../../static/hrimg.png"
                  /> -->
                  景区介绍
                  <!-- <img width="43px" src="../../../static/hrimg.png" />
                  <br />
                  <span>DONGTAI TIAOZINI</span> -->
                </div>
              </div>

              <div class="scenic">
                <div style="position: relative">
                  <span class="video-box"></span>
                  <!-- <img
                    style="
                      position: absolute;
                      left: -118px;
                      top: -97px;
                      z-index: 1;
                      pointer-events: none;
                    "
                    src="../../../static/videoBg.png"
                    alt=""
                  /> -->
                  <video
                    class="about_video"
                    poster="~static/videoFm.png"
                    controls="controls"
                    src="http://117.80.146.163:18080/tznmh/tzn_img/tiaozini.mp4"
                    type="video/mp4"
                  ></video>
                  <!-- <img class="generalbg" src="../../../static/generalbg.png" />
                  <img class="general03" src="../../../static/general03.png" />
                  <img class="general04" src="../../../static/general04.png" /> -->
                </div>
                <div style="position: relative; z-index: 2">
                  <img class="general01" src="../../../static/general01.png" />
                  <img class="general02" src="../../../static/general02.png" />
                  <img class="general05" src="../../../static/general05.png" />
                  <p
                    v-html="decodeHTML(tzn_gkText[0] && tzn_gkText[0].content)"
                  ></p>
                  <!-- <p class="general06">带你走进美丽的 条子泥</p> -->
                </div>
              </div>
            </div>
            <!-- <div
              class="generalImg"
              :style="{ 'background-image': 'url(' + backgroundImg + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat' }"
            ></div>-->
          </div>
          <div
            id="introduce"
            class="introduce"
            style="padding: 120px 80px 0 80px"
          >
            <div class="hr-more">景物介绍</div>
            <div style="width: 77%; margin: 0 auto">
              <div class="introduce_tabs">
                <span
                  v-for="(item, index) in tabsList"
                  :key="item.name"
                  @click="ApiintroduceList(String(index))"
                >
                  <img :src="item.src" />
                  <p>{{ item.name }}</p>
                </span>
              </div>
              <div style="margin-top: 80px; padding-left: 100px; display: flex">
                <div class="scene_tabsL">
                  <!-- <p class="textSpan">{{ introduceList[0].title }}</p> -->
                  <p
                    class="introduceText"
                    v-html="
                      decodeHTML(introduceList[0] && introduceList[0].content)
                    "
                  ></p>
                </div>
                <div class="scene_tabsR">
                  <!-- <div class="carouselbg"></div> -->
                  <img
                    style="position: absolute; z-index: 9"
                    src="../../../static/introducebg01.png"
                    alt=""
                  />
                  <el-carousel class="scene_tabsR_carousel">
                    <el-carousel-item v-for="i in introduceList" :key="i.id">
                      <el-image
                        style="height: 600px"
                        :fit="'cover'"
                        :src="i.thumbnail"
                      ></el-image>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
          </div>
          <div id="greatDeeds">
            <div class="hr-more">历史回眸</div>
            <div class="greatDeeds">
              <div style="display: flex; position: relative">
                <div class="greatDeeds_r">
                  <div v-for="(item, index) in greatDeeds_list" :key="item.id">
                    <el-image
                      v-if="index % 2 == 0"
                      class="greatDeeds_imgL"
                      :src="item.thumbnail"
                      fit="contain"
                    ></el-image>
                    <span>
                      <h3>{{ item.title }}</h3>
                      <h5>{{ item.remark }}</h5>
                    </span>
                    <el-image
                      v-if="index % 2 != 0"
                      class="greatDeeds_imgR"
                      :src="item.thumbnail"
                      fit="contain"
                    ></el-image>
                  </div>
                </div>
                <div class="greatDeeds_bzt">
                  <div v-for="(item, index) in greatDeeds_list" :key="item.id">
                    <h2>0{{ ++index }}</h2>
                  </div>

                  <!-- <h2 style="top: 10%">01</h2>
                  <h2 style="top: 20%">02</h2>
                  <h2 style="top: 30%">03</h2> 
                  <h2 style="top: 40%">04</h2>
                  <h2 style="top: 50%">05</h2>
                  <h2 style="top: 60%">06</h2>
                  <h2 style="top: 70%">07</h2>
                  <h2 style="top: 80%">08</h2> -->
                </div>
              </div>
            </div>
          </div>
          <div id="geography">
            <div class="hr-more">交通指南</div>
            <div class="geography">
              <div class="greatDeeds_2">
                <p
                  v-html="
                    decodeHTML(tzn_jtznText[0] && tzn_jtznText[0].content)
                  "
                ></p>
              </div>
              <!-- https://apis.map.qq.com/uri/v1/marker?marker=coord:32.760766,120.908494;title:条子泥游客中心;&referer=myapp
              https://apis.map.qq.com/uri/v1/search?keyword=条子泥景区游客中心&referer=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77
              //uri.amap.com/marker?position=120.957603, 32.759412&name=条子泥景区&src=&coordinate=&callnative=1 -->
              <iframe
                class="map"
                v-if="flag"
                name="my-iframe"
                id="my-iframe"
                src="https://apis.map.qq.com/uri/v1/marker?marker=coord:32.760766,120.908494;title:条子泥游客中心;&referer=myapp"
                frameborder="0"
                scrolling="no"
              ></iframe>
            </div>
          </div>
        </div>
        <leftFloat :floatList="floatList" @showMap="showMap" />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as api from "@/api/test/test";
export default {
  name: "About",
  components: {
    leftFloat: () => import("../../components/leftFloat.vue"),
  },
  data() {
    return {
      floatList: {
        list: [
          { name: "条子泥印象", url: "flush" },
          { name: "条子泥风光", url: "/scenery" },
          { name: "条子泥动态", url: "/dynamic" },
        ],
        title: "走进条子泥",
      },
      list: ``,
      slideshowList: ``,
      // backgroundImg: ``,
      introduceList: {},
      tabsList: [
        { name: "7 2 0", src: require("../../../static/tabs01.png") },
        { name: "两分水", src: require("../../../static/tabs02.png") },
        { name: "一线潮", src: require("../../../static/tabs03.png") },
        { name: "中国红", src: require("../../../static/tabs04.png") },
        { name: "天之镜", src: require("../../../static/tabs05.png") },
        { name: "潮汐树", src: require("../../../static/tabs06.png") },
      ],
      activeNum: 4,
      tzn_gkText: ``,
      tzn_jtznText: ``,
      greatDeeds_list: ``,
      flag: false,
    };
  },
  methods: {
    ApiintroduceList(key) {
      console.log(key, 99);
      switch (key) {
        case "0":
          api.tzn_sdjs().then((res) => {
            this.introduceList = res;
          });
          break;
        case "1":
          api.tzn_efs().then((res) => {
            this.introduceList = res;
          });
          break;
        case "2":
          api.tzn_sdtm().then((res) => {
            this.introduceList = res;
          });
          break;
        case "3":
          api.tzn_gcth().then((res) => {
            this.introduceList = res;
          });
          break;
        case "4":
          api.tzn_hqxq().then((res) => {
            this.introduceList = res;
          });
          break;
        case "5":
          api.tzn_sdsj().then((res) => {
            this.introduceList = res;
          });
          break;
        default:
          break;
      }
    },
    decodeHTML(str) {
      var s = "";
      if (!str || str.length == 0) return "";
      s = str.replace(/&amp;/g, "&");
      s = s.replace(/&lt;/g, "<");
      s = s.replace(/&gt;/g, ">");
      s = s.replace(/&nbsp;/g, " ");
      s = s.replace(/&#39;/g, "'");
      s = s.replace(/&quot;/g, '"');
      return s;
    },
    showMap() {
      this.flag = true;
    },
    backTop() {
      const that = this;
      let ispeed = Math.floor(that.scrollTop / 5);
      document.documentElement.scrollTop = document.body.scrollTop =
        that.scrollTop + ispeed;
      if (that.scrollTop === 0) {
        clearInterval(timer);
      }
    },
  },
  created() {},
  mounted() {
    api.tzn_sdjs().then((res) => {
      this.introduceList = res;
    });
    api.slideshow().then((res) => {
      this.slideshowList = res;
    });
    api.tzn_gk().then(({ data }) => {
      this.tzn_gkText = data;
    });
    api.tzn_jtzn().then(({ data }) => {
      this.tzn_jtznText = data;
    });
    api.tzn_zdsj().then(({ data }) => {
      this.greatDeeds_list = data;
    });
    // api.list().then((res) => {
    //   this.list = res[0];
    //   this.backgroundImg = res[1].thumbnail;
    // });
  },
  destroyed() {
  },
};
</script>
<style scoped>
.scene_tabsR_carousel >>>.el-carousel__container{
  height: 476px;
}
.greatDeeds {
  height: 800px;
  width: 70%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.map {
  width: 100vw;
  height: 100%;
  margin-left: 20px;
}
.geography {
  height: 590px;
  display: flex;
  margin: 0 auto;
  width: 90%;
}
.greatDeeds_imgL {
  width: 357px;
  height: 200px;
}
.greatDeeds_imgR {
  width: 357px;
  height: 200px;
}
.scene_tabsL {
  width: 400px;
  margin-right: 50px;
  display: flex;
  align-items: center;
}
.scene_tabsR {
  width: 880px;
  position: relative;
}
.scene_tabsR > img {
  width: 990px;
  height: 540px;
}
.scene_tabsR_carousel {
  width: 110%;
  margin-right: 60px;
  margin-top: 30px;
}
.about_video {
  object-fit: fill;
  margin-top: -81px;
  width: 550px;
  height: 500px;
}
.video-box {
  width: 783px;
  height: 700px;
  background-image: url(~static/videoBg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: -116px;
  top: -29%;
}
>>> .el-breadcrumb {
  line-height: 92px;
  font-size: 24px;
}
>>> .el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #509ab1 !important;
}
.top-title {
  background-image: url(/static/img/title.ab49d158.png);
  background-size: cover;
  background-repeat: no-repeat;
  letter-spacing: 5px;
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  line-height: 92px;
  font-size: 24px;
}
.home-icon {
  width: 20px;
  height: 20px;
  margin: 0 16px 0 53px;
}
.top-title > span:hover {
  cursor: pointer;
  color: #509ab1;
}
.Aboutbg {
  position: absolute;
  z-index: -1;
}
.introduce_tabs img {
  width: 113px;
}
.introduce_tabs,
.scenic {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.introduce_tabs:before,
.introduce_tabs:after,
.scenic:before,
.scenic:after {
  content: "";
  display: block;
}
.introduce_tabs p,
.textSpan {
  font-size: 25px;
  font-family: PangMenZhengDao-Cu;
  font-weight: bold;
  color: #509ab1;
}
.debris {
  width: 100%;
  margin-top: -157px;
  z-index: 8;
  background: transparent;
  position: relative;
}
.active {
  background-color: #509ab1;
}
.greatDeeds_r {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.greatDeeds_r h3 {
  width: 55px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #509ab1;
  line-height: 41px;
  white-space: nowrap;
}
.greatDeeds_r h5 {
  width: 357px;
  height: 18px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.greatDeeds_r > div {
  width: 1000px;
  margin: 40px;
  height: 170px;
  display: flex;
  justify-content: space-around;
}
.greatDeeds_bzt {
  height: 100%;
  width: 2px;
  margin: 0 auto;
  border: none;
  background: darkgray;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.greatDeeds_bzt > div {
  height: 170px;
  margin: 40px 0;
}
.greatDeeds_bzt > div > h2 {
  right: -19px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #509ab1;
  border-radius: 25px;
  text-align: center;
}
img[class^="general"] {
  position: absolute;
  z-index: -99;
}
.generalbg {
  left: -45%;
  top: -27%;
}
.general01 {
  right: -27%;
}
.general02 {
  right: -36%;
  top: 65%;
}
.general03 {
  height: 140px;
  left: -18%;
  top: -6%;
}
.general04 {
  bottom: -25%;
  left: 88%;
  z-index: -1;
}
.general05 {
  left: -30%;
}
.general06 {
  writing-mode: tb-rl;
  position: absolute;
  right: -51%;
  top: 4%;
  font-size: 19px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #363a31;
  line-height: 24px;
}
.Hcontent {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 160px;
  background: url("../../../static/img3bg.png") no-repeat 100% 100%;
  background-size: cover;
  height: 600px;
}
.content-text {
  width: 142px;
  height: 231px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: url("../../../static/img13.png") no-repeat 100% 100%;
}
.content-text > p {
  width: 30px;
  font-size: 21px;
  font-weight: bold;
  line-height: 29px;
  color: #317981;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* 静态资源 */
.content-grid {
  width: 220px;
  height: 320px;
  z-index: 12;
  position: relative;
}

.introduceText {
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  /* -webkit-box-orient: vertical; */
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 10px;
}
.introduceText >>> span {
  /* font-family: 'tzn-font' !important; */
  font-size: 21px !important;
  font-family: PangMenZhengDao-Cu !important;
}
.introduce {
  height: 1100px;
  /* background: url("../../../static/introducebg.png") no-repeat; */
  background-size: cover;
}
.el-tabs__content > div {
  display: flex;
}
.greatDeeds_2 {
  margin-left: 10%;
  /* width: 45%; */
  height: 100%;
  overflow-y: auto;
}
.greatDeeds_2 > div {
  padding-left: 40px;
  background-position-y: 50%;
}
.icon1 {
  background: url("../../../static/msg.png") no-repeat;
}
.icon2 {
  background: url("../../../static/site.png") no-repeat;
}
.icon3 {
  background: url("../../../static/time.png") no-repeat;
}
.icon4 {
  background: url("../../../static/icon4.png") no-repeat;
}

>>> .el-tabs__content {
  height: 300px;
}
.hr-more {
  white-space: nowrap;
  letter-spacing: 3px;
  line-height: 250px;
  z-index: 9;
  width: 452px;
  height: 250px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: -19px;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  background: url("../../../static/Hrbg.png");
  font-size: 35px;
  font-family: PangMenZhengDao-Cu;
  font-weight: 400;
  color: #509ab1;
}
.hr-more img {
  margin: 0 6px;
}
.hr-more span {
  width: 119px;
  height: 9px;
  font-size: 12px;
  font-family: Arial;
  font-weight: 400;
  color: #b3b3b3;
  line-height: 24px;
}
.about {
  position: relative;
}
.general {
  display: flex;
  /* box-shadow: 0px -7px 18px 0px rgb(0 0 0 / 10%); */
  padding-left: 20px;
  margin-bottom: 50px;
  /* background: url("../../../static/generalbg.png") no-repeat -6% -49%; */
}
.general p {
  text-indent: 28px;
  text-align-last: start;
  width: 430px;
  height: 400px;
  line-height: 2.5;
  overflow-y: auto;
  /* writing-mode: tb-rl; */
  /* letter-spacing: 12px; */
}
.generalImg {
  flex: 1;
}
.el-main {
  padding: 0;
  overflow-x: hidden;
}
</style>